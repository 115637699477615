import React from 'react';
import PropTypes from 'prop-types';

import * as classes from './Jumbotron.module.css';

const Jumbotron = (props) => {
  const style = props.fullHeight ? {height: '100vh'} : {};

  return (
    <div className={classes.Wrapper} style={style}>
      <h1 className={classes.Headline}>{props.title}</h1>
      <p className={classes.Subtitle}>{props.subtitle}</p>
      {props.children}
    </div>
  );
};

Jumbotron.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
};

export default Jumbotron;
