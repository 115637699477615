import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import Jumbotron from '../components/Jumbotron/Jumbotron';

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'seo.title.404' })} />
      <div className="header-separator" style={{ paddingTop: '64px' }} />
      <Jumbotron
        title={intl.formatMessage({ id: 'pages.404.title' })}
        subtitle={intl.formatMessage({ id: 'pages.404.subtitle' })}
        fullHeight={true}
      >
        <Link to="/">
          <Button variant="light">
            <FormattedMessage id="pages.404.button_text" />
          </Button>
        </Link>
      </Jumbotron>
    </Layout>
  );
};

export default NotFoundPage;
